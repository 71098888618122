export const getAccessToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getRefreshToken = () => {
  const token = localStorage.getItem("refresh-token");
  return token;
};

export const storeAccessToken = (a_token) => {
  const token = localStorage.setItem("token", a_token);
  return token;
};

export const storeRefreshToken = (r_token) => {
  const token = localStorage.setItem("refresh-token", r_token);
  return token;
};

export const removeAccessToken = () => {
  localStorage.removeItem("token");
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refresh-token");
};

export const convertToFormData = (data) => {
  const fd = new FormData();
  for (const [key, value] of Object.entries(data)) {
    fd.append(key, value);
  }
  return fd;
};

export const truncateString = (str, len = 15) => {
  if (!str || str === null) {
    return "";
  }
  return str.length > len ? `${str}`.substring(0, len).trim() + "..." : str;
};

export const sortedChats = (data) => {
  return data.slice().sort((a, b) => {
    const dateA = new Date(a?.lastMessage?.createdAt);
    const dateB = new Date(b?.lastMessage?.createdAt);

    if (isNaN(dateA) && !isNaN(dateB)) {
      return 1;
    } else if (!isNaN(dateA) && isNaN(dateB)) {
      return -1;
    } else {
      return dateB - dateA;
    }
  })

};
