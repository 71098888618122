import React from 'react'
import { Outlet } from 'react-router-dom';
// import Footer from './../components/common/Footer';
import Navbar from './../components/common/Navbar';
import ScrollToTopOnLocationChange from '../components/common/ScrollToTop';

const RootLayout = () => {
    return (
        <div>
            <>
                <ScrollToTopOnLocationChange />
                <Navbar />
                <Outlet />
                {/* <Footer /> */}
            </>


        </div>
    )
}

export default RootLayout;