import React from 'react'
import Account from '../components/Account/Account'

function AccountPage() {
  return (
    <>
      <Account />
    </>
  )
}

export default AccountPage