import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const SCAN_PATH = "/scan";

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const pathname = location.pathname;
    return (
        auth?.accessToken
            ? <Outlet />
            : <Navigate to={pathname.includes(SCAN_PATH) ? "/register" : "/login"} state={{ from: location }} replace />
    );
}

export default RequireAuth;