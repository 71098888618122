import React from 'react';
import styles from './Scanner.module.css';
import { useNavigate } from 'react-router-dom';
// import QrReader from 'react-qr-scanner';
import camclose from './assets/camclose.svg';
import s2head1 from './assets/s2head1.svg';
// import cam2 from './assets/cam.png';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
// import { QrReader } from "react-qr-reader";

const delay = 1000;

const Scanner = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/main', { replace: true });
  };

  // const handleScan = (data) => {
  //   console.log({ data });
  //   if (data !== null) {
  //     const text = data.text;
  //     if (!text) {
  //       return;
  //     }
  //     window.location.replace(text);
  //   }
  // };

  // const handleError = (err) => {
  //   console.error(err);
  // };

  const onNewScanResult = (decodedText, decodedResult) => {
    // handle decoded results here
    const text = decodedText;
    // 'www.mastersticker.com';
    const redirect =
      text.includes('https://staging.mastersticker.com') ||
      text.includes('master-sticker-staging.netlify.app') ||
      text.includes('www.mastersticker.com') ||
      text.includes('https://development.d2eibjnqu641r7.amplifyapp.com');
    if (redirect) {
      window.location.replace(text);
    } else {
      Swal.fire('Oops!!!', 'Invalid QR Code', 'error');
    }
  };

  return (
    <div className={styles.main}>
      <center>
        <img alt="s2head1" className={styles.heading} src={s2head1} />

        <br />

        <div className={styles.container}>
          {/* <QrReader
            delay={delay}
            facingMode={'environment'}
            onError={handleError}
            onScan={handleScan}
            // style={{
            //   height: '85vh',
            //   width: '100%',
            //   position: 'absolute',
            //   top: '10%',
            //   left: '0%',
            // }}
            style={{ width: '100%' }}
            className={styles.camSize}
          /> */}
          <Html5QrcodePlugin
            style={{ width: '100%' }}
            className={styles.camSize}
            fps={10}
            qrbox={{ width: 250, height: 250 }}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
          />
        </div>

        {/* <center>
          <img alt="" src={cam2} className={styles.cam2} />
        </center> */}

        <img
          alt=""
          onClick={handleBack}
          src={camclose}
          className={styles.camclose}
        />
        {/* <br /> */}
      </center>
    </div>
  );
};

export default Scanner;
