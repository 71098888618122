import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import { submitSignUp } from "../../api/auth/auth";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";
import styles from "./RegisterForm.module.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RegisterForm = () => {
  const navigate = useNavigate();
  const from = "/main";

  const [isLoading, setIsLoading] = useState(false);

  const { setAuth } = useAuth();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Your password is too short.')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])/,
        'Password should contain both letters and numbers'
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      checkTnc: false,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  const handleSignup = async (values) => {
    if (!values.checkTnc) {
      Swal.fire(
        "Terms and Conditions",
        "You need to accept the terms and conditions to proceed",
        "error"
      );
      return;
    }

    setIsLoading(true);

    const res = await submitSignUp(values);

    setIsLoading(false);

    if (res.error) {
      if (res.code === "ERR_NETWORK") {
        Swal.fire("Something went wrong", res.message, "error");
      } else {
        Swal.fire("Invalid Credentials", res.message, "error");
      }
      return;
    }
    setAuth({
      user: res.data.user,
      accessToken: res.data.token,
      role: res.data.user.role,
    });
    formik.resetForm();
    navigate(from, { replace: true });
  };

  const [showPassword, setShowPassword] = useState(false);
  const passwordError = formik.touched.password && formik.errors.password;
  const emailError = formik.touched.email && formik.errors.email;

  return (
    <center>
      <div className={styles.main}>
        <div className={styles.s3head1}>Create the sticker account</div>
        <center>
          {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
          <form onSubmit={formik.handleSubmit}>
            <input
              className={styles.sinp1}
              type="text"
              placeholder="Full Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <br />
            <input
              className={styles.sinp1}
              type="email"
              placeholder="Mail ID"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              {...formik.getFieldProps('email')}
            />

            {emailError && (
              <div className={styles.errorText}>{formik.errors.email}</div>
            )}

            <br />

            <input
              // className={`${styles.sinp2} ${styles.sinp1} ${styles.passwordInput} ${
              //     passwordError && styles.errorInput
              //   }`}
              className={styles.sinp1}
              type={!showPassword ? "password" : "text"}
              placeholder="Create Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              {...formik.getFieldProps('password')}
            />

            <div className={styles.hideBtn2}>
              <button
                className={styles.passwordToggleButton2}
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            {passwordError && (
              <div className={styles.errorText2}>{formik.errors.password}</div>
            )}

            <br />

            <div className={styles.sflex}>
              <div className={styles.sflex1}>
                <input
                  type="checkbox"
                  defaultChecked={formik.values["checkTnc"]}
                  name="checkTnc"
                  className={styles.sinp2}
                  onChange={formik.handleChange}
                />
                {/* <Field type="checkbox" name="check" className="sinp2" /> */}
              </div>
              <div className={styles.sflex2}>
                <label htmlFor="checkbox" className={styles.sinp2t}>
                  I have read and accept MasterTag’s <u>Terms of Service </u>
                  and understand MasterTag’s <u>Privacy Policy</u> applies.
                </label>
              </div>
            </div>
            <button
              disabled={isLoading}
              type="submit"
              className={`${styles.sBtn} ${isLoading && styles.btnDisabled}`}
            >
              Finish
            </button>
          </form>

          <br />

          <Link to="/login">
            <img
              alt=""
              src="./images/s3head3oo.svg"
              className={styles.s3head3}
            />
          </Link>
        </center>
      </div>
    </center>
  );
};

export default RegisterForm;
