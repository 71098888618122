import React from 'react';
import styles from './StickerCard.module.css';
// packages
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { alpha, styled } from '@mui/material/styles';
import { orange, pink, red } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

// custom
import useSticker from '../../hooks/useSticker';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Loader from '../common/Loader';

// extras
import { convertToFormData, truncateString } from '../../util/util';
import { STICKERS_URL } from '../../util/constants';

// assets
import Edit from './assets/edit.png';

const StickerCard = ({ sticker, getStickers }) => {
  // states
  const [isLoading, setIsLoading] = React.useState(false);
  // custom hooks
  const { setSticker } = useSticker();
  const axios = useAxiosPrivate();

  // react-router-dom
  const navigate = useNavigate();

  // extras/props
  const {
    uid,
    name,
    stickerName,
    email,
    phone,
    socialLink,
    toDisplayName,
    toDisplayPhone,
    toDisplaySocialLink,
    toDisplayEmail,
    alwaysShowData,
    lostModeOn,
  } = sticker;

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: red['A700'],
      '&:hover': {
        backgroundColor: alpha(red['A700'], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: red['A700'],
    },
  }));

  const handleEdit = (sticker) => {
    setSticker(sticker);
    navigate('/main/create', { replace: true });
  };

  const updateSticker = async (data, uid) => {
    setIsLoading(true);
    try {
      const res = await axios.patch(STICKERS_URL + uid, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return {
        error: false,
        data: res.data,
      };
    } catch (err) {
      console.log(err);
      return {
        error: true,
        code: err.response ? err.response.data.code : err.code,
        message: err.response ? err.response.data.message : err.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSticker = async (uid) => {
    setIsLoading(true);
    try {
      const res = await axios.delete(STICKERS_URL + uid);
      return {
        error: false,
        data: res.data,
      };
    } catch (err) {
      return {
        error: true,
        code: err.response ? err.response.data.code : err.code,
        message: err.response ? err.response.data.message : err.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateDisplay = async (e) => {
    console.log(sticker);
    const { name, checked } = e.target;
    let text = `This data will be ${
      checked ? 'visible to' : 'hidden from'
    } everyone!`;
    if (name === 'lostModeOn') {
      text = `The data will be ${
        checked ? 'visible to' : 'hidden from'
      } everyone!`;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${checked ? 'show' : 'hide'} it!`,
    }).then(async (result) => {
      if (result.value) {
        const data = convertToFormData({ [name]: checked });
        console.log({ sticker });
        const res = await updateSticker(data, uid);

        if (res.error) {
          const code = res.code;
          Swal.fire('Something Went Wrong !!!', res.message, 'error');
          if (code === 401 || code === 403) {
            navigate('/login', { replace: true });
          }
          return;
        } else {
          Swal.fire('Yo MasterFam!', res.data.message, 'success');
          getStickers();
        }
      }
    });
  };

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will have to fill details again!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.value) {
        const res = await deleteSticker(sticker.uid);
        if (res.error) {
          const code = res.code;
          Swal.fire('Something Went Wrong !!!', res.message, 'error');
          if (code === 401 || code === 403) {
            navigate('/login', { replace: true });
          }
          return;
        } else {
          Swal.fire('Deleted!', 'Your sticker has been deleted.', 'success');
          getStickers();
        }
      }
    });
  };
  return (
    <div className={styles.sfeed}>
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          <div className={styles.s6head2}>
            {stickerName !== '' ? stickerName : 'Sticker name'}

            <img
              src={Edit}
              onClick={() => handleEdit(sticker)}
              alt="edit"
              className={styles.edits}
            />
          </div>

          {name && name !== '' && (
            <div className={styles.sacc}>
              <div className={styles.sacc1}>
                <div className={styles.acct1}>{truncateString(name)}</div>
              </div>
              <div className={styles.sacc2}>
                <input
                  checked={toDisplayName}
                  name="toDisplayName"
                  onChange={handleUpdateDisplay}
                  type="checkbox"
                  className={styles.sccb}
                />
              </div>
            </div>
          )}

          {phone && phone !== '' && (
            <div className={styles.sacc}>
              <div className={styles.sacc1}>
                <div className={styles.acct1}>{truncateString(phone)}</div>
              </div>
              <div className={styles.sacc2}>
                <input
                  name="toDisplayPhone"
                  checked={toDisplayPhone}
                  onChange={handleUpdateDisplay}
                  type="checkbox"
                  className={styles.sccb}
                />
              </div>
            </div>
          )}

          {email && email !== '' && (
            <div className={styles.sacc}>
              <div className={styles.sacc1}>
                <div className={styles.acct1}>{truncateString(email)}</div>
              </div>
              <div className={styles.sacc2}>
                <input
                  checked={toDisplayEmail}
                  onChange={handleUpdateDisplay}
                  name="toDisplayEmail"
                  type="checkbox"
                  className={styles.sccb}
                />
              </div>
            </div>
          )}

          {socialLink && socialLink !== '' && (
            <div className={styles.sacc}>
              <div className={styles.sacc1}>
                <div className={styles.acct1}>{truncateString(socialLink)}</div>
              </div>
              <div className={styles.sacc2}>
                <input
                  onChange={handleUpdateDisplay}
                  type="checkbox"
                  name="toDisplaySocialLink"
                  checked={toDisplaySocialLink}
                  className={styles.sccb}
                />
              </div>
            </div>
          )}

          {!alwaysShowData && (
            <div className={styles.sacc}>
              <div className={styles.sacc5}>
                <div className={styles.acct1}>Lost Mode On</div>
              </div>
              <div className={styles.sacc6}>
                <PinkSwitch
                  size="small"
                  name="lostModeOn"
                  checked={lostModeOn}
                  className={styles.togSwt}
                  onChange={handleUpdateDisplay}
                />
              </div>
            </div>
          )}

          <br />
          {/* <br /> */}

          {/* <button onClick={() => handleEdit(sticker)} className="feedEdit">
            EDIT
          </button> */}

          <button onClick={handleDelete} className={styles.feedEdit}>
            Delete Sticker
          </button>
        </>
      )}
    </div>
  );
};

export default StickerCard;
