import React from 'react';
import Found from '../components/found/Found';

const FoundPage = () => {
    return (
        <>
            <Found />
        </>
    )
}

export default FoundPage;