import React, { useEffect } from 'react';
import styles from './Account.module.css';
// packages
import { Link, useNavigate } from 'react-router-dom';

// extras
import { STICKERS_URL } from '../../util/constants';

// custom
import useSticker from '../../hooks/useSticker';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import StickerCard from '../stickerCard/StickerCard';

// assets
import addSticker from './assets/addsticker.svg';

const Account = () => {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const { stickers, setStickers } = useSticker();

  const getStickers = async () => {
    try {
      const res = await axios.get(STICKERS_URL);
      // console.log(res.data);
      setStickers(res.data);
    } catch (err) {
      console.log(err);
      const code = err?.response?.status;
      if (code === 401 || code === 403) {
        navigate('/login', { replace: true });
      }
    }
  };

  useEffect(() => {
    getStickers();
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.s3head1}>My Stickers</div>
      <center>
        {stickers.length > 0 ? (
          stickers?.map((sticker) => (
            <StickerCard
              key={sticker.id}
              sticker={sticker}
              getStickers={getStickers}
            />
          ))
        ) : (
          <h3>No stickers found</h3>
        )}

        <br />
        <br />

        <Link to="/main/scan">
          <img alt="" src={addSticker} className={styles.addstk} />
        </Link>

        <br />
      </center>
    </div>
  );
};

export default Account;
