import axios from 'axios';
import { back_url } from "./../util/constants";

export default axios.create({
    baseURL: back_url,
});

const axiosPrivate = axios.create({
    baseURL: back_url,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});


export { axiosPrivate };