import { logOutMe } from "./../api/auth/auth";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        await logOutMe();
    }

    return logout;
}

export default useLogout