import { createContext, useState } from "react";

const StickerContext = createContext({});

export const StickerProvider = ({ children }) => {
    const [stickers, setStickers] = useState([]);
    const [sticker, setSticker] = useState({});
    const [stickerUid, setStickerUid] = useState("");

    return (
        <StickerContext.Provider value={{ sticker, setSticker, stickers, setStickers, stickerUid, setStickerUid }}>
            {children}
        </StickerContext.Provider>
    )
}

export default StickerContext;