import { createContext, useState, useEffect } from "react";
import { CHAT_URL } from "../util/constants";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { sortedChats } from "../util/util";


const ChatContext = createContext({});

export const ChatProvider = ({ children }) => {
    const [sessionId, setSessionId] = useState("");
    const [stickerUid, setStickerUid] = useState("");
    const [owner, setOwner] = useState("");
    const [messages, setMessages] = useState([]);
    const [chats, setChats] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const { auth } = useAuth();

    const axios = useAxiosPrivate();

    const fetchAllChats = async () => {
        // setIsLoading(true);
        try {
            const res = await axios.get(CHAT_URL);
            const temp = sortedChats(res.data)
            setChats(temp);
            console.log(temp);

            return {
                error: false,
            }

        } catch (err) {
            console.log(err);
            const code = err.response ? err.response.data.code : err.code;
            const message = err.response ? err.response.data.message : err.message;


            return {
                error: true,
                code,
                message,
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllNotifications = async () => {
        try {
            const notificationRes = await axios.get(`${CHAT_URL}notifications`);
            setNotifications(notificationRes?.data?.content);

            return {
                error: false,
            }
        } catch (err) {
            console.log(err);
            const code = err.response ? err.response.data.code : err.code;
            const message = err.response ? err.response.data.message : err.message;


            return {
                error: true,
                code,
                message,
            }
        }
    }

    const updateChatNotifications = async (data) => {
        // setIsLoading(true);
        try {
            const notificationRes = await axios.post(`${CHAT_URL}notifications`, data);
            console.log(notificationRes.data);

            return {
                error: false,
            }

        } catch (err) {
            console.log(err);
            const code = err.response ? err.response.data.code : err.code;
            const message = err.response ? err.response.data.message : err.message;


            return {
                error: true,
                code,
                message,
            }
        }
    };

    useEffect(() => {
        if (auth?.user && auth?.accessToken) {
            fetchAllChats();
            fetchAllNotifications();
        }
    }, [auth]);

    // useEffect(() => {
    //     console.log(notifications);
    // }, [notifications]);

    return (
        <ChatContext.Provider value={{ sessionId, setSessionId, stickerUid, setStickerUid, owner, setOwner, messages, setMessages, chats, setChats, isLoading, setIsLoading, fetchAllChats, notifications, setNotifications, fetchAllNotifications, updateChatNotifications }}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatContext;