import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import AccountPage from "./pages/AccountPage";
import FoundPage from "./pages/FoundPage";
import StickerPage from "./pages/StickerPage";
import ScannerPage from "./pages/ScannerPage";
import FeedPage from "./pages/FeedPage";
import RootLayout from "./layout/RootLayout";
import RequireAuth from "./components/extras/RequireAuth";
import PersistLogin from "./components/extras/PersistLogin";
import ChatPage from "./pages/ChatPage";
import AlertPage from "./pages/AlertPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { SocketProvider } from "./context/SocketContext";




function App() {

  return (
    <>
      <BrowserRouter>
        <SocketProvider>
          <Routes>
            <Route path="/" element={<RootLayout />}>
              <Route path="" element={<StickerPage />} />
              <Route path="register" element={<RegisterPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="forget-password" element={<ForgetPasswordPage />} />
              <Route path="reset-password" element={<ResetPasswordPage />} />
              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route path="main" element={<AccountPage />} />
                  <Route path="main/scan"
                    element={<ScannerPage />} />
                  <Route path="main/create"
                    element={<FeedPage />} />
                  <Route path="main/chats" element={<AlertPage />} />
                  <Route path="main/chats/:id" element={<ChatPage />} />
                </Route>
              </Route>
              <Route path="display/chats/:id" element={<ChatPage />} />
              <Route path="display/:stickerId" element={<FoundPage />} />
              <Route path="/*" element={<Navigate to="/" />} />

            </Route>

          </Routes>
        </SocketProvider>
      </BrowserRouter>
    </>

  );
}

export default App;

