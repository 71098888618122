import React from 'react';
import Alerts from '../components/alerts/Alerts';

const AlertPage = () => {
  return (
    <>
      <Alerts />
    </>
  );
};

export default AlertPage;
