import React, { useEffect } from 'react';
import styles from './FeedForm.module.css';

// packages
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// extras
import { convertToFormData } from '../../util/util';
import { STICKERS_URL } from '../../util/constants';

// custom
import useSticker from '../../hooks/useSticker';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const FeedForm = () => {
  // state
  const [isLoading, setIsLoading] = React.useState(false);

  // react-router-dom
  const navigate = useNavigate();

  // custom hooks
  const axios = useAxiosPrivate();
  const { sticker, stickerUid, setSticker, setStickerUid } = useSticker();

  useEffect(() => {
    if (!sticker?.uid && stickerUid === '') {
      navigate('/main', { replace: true });
    }
  }, []);

  const formik = useFormik({
    initialValues: sticker
      ? sticker
      : {
          stickerName: '',
          name: '',
          displayPic: undefined,
          phone: '',
          email: '',
          socialLink: '',
          toDisplayName: true,
          toDisplayEmail: true,
          toDisplayPhone: true,
          toDisplaySocialLink: true,
          alwaysShowData: false,
        },
    onSubmit: (values) => handleSubmit(values),
  });

  const createSticker = async (data, uid) => {
    try {
      const res = await axios.post(STICKERS_URL + uid, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setStickerUid('');
      return {
        error: false,
        data: res.data,
      };
    } catch (err) {
      console.log(err);
      return {
        error: true,
        code: err.response ? err.response.data.code : err.code,
        message: err.response ? err.response.data.message : err.message,
      };
    }
  };

  const updateSticker = async (data, uid) => {
    try {
      const res = await axios.patch(STICKERS_URL + uid, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSticker({});
      return {
        error: false,
        data: res.data,
      };
    } catch (err) {
      console.log(err);
      return {
        error: true,
        code: err.response ? err.response.data.code : err.code,
        message: err.response ? err.response.data.message : err.message,
      };
    }
  };

  const handleSubmit = async (values) => {
    let res;
    setIsLoading(true);
    if (sticker?.uid) {
      let { uid, id, ...rest } = values;
      rest = convertToFormData(rest);
      res = await updateSticker(rest, uid);
    } else {
      const data = convertToFormData(values);
      res = await createSticker(data, stickerUid);
    }
    setIsLoading(false);
    if (res.error) {
      const code = res.code;
      Swal.fire('Something Went Wrong !!!', res.message, 'error');
      if (code === 401 || code === 403) {
        navigate('/login', { replace: true });
      }
      return;
    } else {
      Swal.fire('Yo MasterFam!', res.data.message, 'success');
      navigate('/main');
      return;
    }
  };

  const handleBack = async () => {
    navigate('/main', { replace: true });
  };

  return (
    <div className={styles.main}>
      <div className={styles.s3head1}>My Sticker</div>
      <center>
        <div className={styles.sfeed}>
          <div className={styles.s6head2}>Feed Sticker Details</div>
          <form>
            <input
              className={styles.sinp4}
              type="text"
              placeholder="Sticker Name"
              name="stickerName"
              value={formik.values.stickerName}
              onChange={formik.handleChange}
            />
            <br />
            {/* <input
                            className={styles.sinp4}
                            placeholder="Add image"
                            id="photo" name="photo"
                            type="file"
                            onChange={(event) => {
                                formik.setFieldValue("displayPic", event.currentTarget.files[0]);
                            }} />
                        {sticker?.uid && <div><span>Please upload an image to update the display pic.</span></div>}
                        <br /> */}
            <br />
            <div className={styles.feedh2}>Personal Details</div>

            <input
              className={styles.sinp4}
              type="text"
              placeholder="Contact person name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <br />
            <input
              className={styles.sinp4}
              type="text"
              name="phone"
              placeholder="Contact phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />

            <br />
            <input
              className={styles.sinp4}
              type="text"
              name="email"
              placeholder="Contact mail id"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <br />
            <br />
            <div className={styles.feedh2}>Social Media</div>
            <input
              className={styles.sinp4}
              type="text"
              name="socialLink"
              placeholder="Any social media link"
              value={formik.values.socialLink}
              onChange={formik.handleChange}
            />

            <br />
            {
              <div className={styles.feedformControl}>
                <input
                  checked={formik.values.alwaysShowData}
                  type="checkbox"
                  onChange={(event) => {
                    formik.setFieldValue(
                      'alwaysShowData',
                      event.target.checked
                    );
                  }}
                  name="alwaysShowData"
                  id=""
                />
                <p>Always Show MasterSticker Data</p>
              </div>
            }
            <div>
              <button
                disabled={isLoading}
                onClick={formik.handleSubmit}
                type="submit"
                className={`${styles.sBtn} ${isLoading && styles.btnDisabled}`}
              >
                {sticker.uid ? 'Update' : 'Activate'}
              </button>
            </div>

            <button onClick={handleBack} className={styles.feedFormBackbtn}>
              Back
            </button>
            <br />
          </form>
        </div>
      </center>
      <br />
      <br />
    </div>
  );
};

export default FeedForm;
