import ForgetPassword from './../components/forgetPassword/ForgetPassword';

import React from 'react';

const ForgetPasswordPage = () => {
  return (
    <>
      <ForgetPassword />
    </>
  );
};

export default ForgetPasswordPage;
