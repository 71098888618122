import React, { useState, useEffect, useContext } from "react";
import styles from "./chat.module.css";

// custom
import Loader from "../common/Loader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useChat from "../../hooks/useChat";

// packages
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import io from "socket.io-client";
import ScrollableFeed from "react-scrollable-feed";

// extras
import {
  END_CHAT_BY_OWNER_URL,
  GUEST_MESSAGES_URL,
  GUEST_SEND_MESSAGE_URL,
  OWNER_MESSAGES_URL,
  OWNER_SEND_MESSAGE_URL,
  back_url,
} from "../../util/constants";

// assests
import Left from "./assets/Left.png";
import Avatar from "./assets/Avatar.png";
import Send from "./assets/Send.png";
import Spinner from "./assets/spinner.gif";
import useAuth from "../../hooks/useAuth";
import SocketContext from "../../context/SocketContext";

const Chat = () => {

  const { socket } = useContext(SocketContext);

  // hooks
  const navigate = useNavigate();
  const params = useParams();

  // custom hooks
  const axios = useAxiosPrivate();
  const {
    sessionId,
    setSessionId,
    setStickerUid,
    stickerUid,
    setOwner,
    owner,
    messages,
    setMessages
  } = useChat();
  const { auth } = useAuth();

  // state
  const [chatId] = useState(params.id);
  const [newMessage, setNewMessage] = useState("");
  const [isLoadingForMessages, setIsLoadingForMessages] = useState(false);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  // useEffect(() => {
  //   console.log({ messages });
  // }, [messages]);

  useEffect(() => {
    if (auth?.user?.id !== owner) {
      socket.emit("setup", params.id);
    }
    socket.on("connected", (data) => {
      setSocketConnected(true);
    });

    // socket.on("receiveMessage", (newMessageRecieved) => {
    //   setMessages((prev) => [...prev, newMessageRecieved]);
    // });

    socket.on("endChat", () => {
      if (auth?.user?.id !== owner) {
        Swal.fire({
          icon: "error",
          title: "Oops Something Went Wrong!",
          text: "Chat has been ended by the owner.",
        });
        handleBack();
      }
    });

    return () => {
      socket.off("setup", params.id);
    };

    // eslint-disable-next-line
  }, []);

  const fetchAllMessages = async () => {
    let id = sessionId;
    if (sessionId === "") {
      id = params.id;
    }
    try {
      const res = await axios.post(OWNER_MESSAGES_URL, {
        sessionId: id,
      });
      setMessages(res.data.chats);
      setIsActive(res.data.info.isActive);
      setSessionId(res.data.info.sessionId);
    } catch (err) {
      console.log(err);
      const code = err.response ? err.response.data.code : err.code;
      const message = err.response ? err.response.data.message : err.message;
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: message,
      });
      if (code === 401 || code === 403) {
        navigate("/login", { replace: true });
      } else if (code === 404 || code === 400) {
        navigate("/main/chats", { replace: true });
      }
    } finally {
      setIsLoadingForMessages(false);
    }
  };

  const fetchAllMessagesGuest = async () => {
    try {
      const res = await axios.post(
        GUEST_MESSAGES_URL,
        {
          sessionId: sessionId || params.id,
        },
        {
          withCredentials: true,
        }
      );
      setMessages(res.data.chats);
      setStickerUid(res.data.info.uid);
      setSessionId(res.data.info.sessionId);
    } catch (err) {
      console.log(err);
      const code = err.response ? err.response.data.code : err.code;
      const message = err.response ? err.response.data.message : err.message;
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: message,
      });
      if (code === 404 || code === 400) {
        const uid =
          stickerUid === "" ? sessionStorage.getItem("stickerUid") : stickerUid;
        if (uid) {
          navigate(`/display/${uid}`, {
            replace: true,
          });
        } else {
          navigate(`/`, { replace: true });
        }
      }
    } finally {
      setIsLoadingForMessages(false);
    }
  };

  const sendMessageByOwner = async () => {
    setLoadingSendMessage(true);
    try {
      const res = await axios.post(OWNER_SEND_MESSAGE_URL, {
        sessionId: sessionId,
        content: newMessage,
        sender: "Owner",
        owner: owner,
      });
      socket.emit("sendMessage", res.data);
      setNewMessage("");
    } catch (err) {
      console.log(err);
      const code = err.response ? err.response.data.code : err.code;
      const message = err.response ? err.response.data.message : err.message;
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: message,
      });
      if (code === 401 || code === 403) {
        navigate("/login", { replace: true });
      } else if (code === 404) {
        navigate("/main/chats", { replace: true });
      }
    } finally {
      setLoadingSendMessage(false);
    }
  };

  const sendMessageByGuest = async () => {
    setLoadingSendMessage(true);
    try {
      const res = await axios.post(
        GUEST_SEND_MESSAGE_URL,
        {
          sessionId: sessionId || params.id,
          content: newMessage,
          sender: "Guest",
          owner: owner,
          read: false,
          delivered: false,
        },
        { withCredentials: true }
      );
      console.log(owner);
      socket.emit("sendMessage", res.data);
      setNewMessage("");
    } catch (err) {
      console.log(err);
      const code = err.response ? err.response.data.code : err.code;
      const message = err.response ? err.response.data.message : err.message;
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: message,
      });
      if (code === 404 || code === 400) {
        const uid =
          stickerUid === "" ? sessionStorage.getItem("stickerUid") : stickerUid;
        if (uid) {
          navigate(`/display/${uid}`, {
            replace: true,
          });
        } else {
          navigate(`/`, { replace: true });
        }
      }
    } finally {
      setLoadingSendMessage(false);
    }
  };

  const endChatByOwner = async () => {
    setLoadingSendMessage(true);
    try {
      await axios.post(END_CHAT_BY_OWNER_URL, {
        sessionId: sessionId,
      });
      setIsActive(false);
      socket.emit("endChat", sessionId);
      Swal.fire("Successful!", "Your chat has been ended.", "success");
    } catch (err) {
      console.log(err);
      const code = err.response ? err.response.data.code : err.code;
      const message = err.response ? err.response.data.message : err.message;
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: message,
      });
      if (code === 401 || code === 403) {
        navigate("/login", { replace: true });
      }
    } finally {
      setLoadingSendMessage(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    let user = owner;
    if (user === "") {
      user = sessionStorage.getItem("owner");
    }
    if (auth?.user && auth?.user?.id === user) {
      setIsOwner(true);
      fetchAllMessages();
    } else {
      fetchAllMessagesGuest();
    }
    return () => {
      sessionStorage.removeItem("stickerUid");
      sessionStorage.removeItem("owner");
      sessionStorage.removeItem("sessionId");
      setStickerUid("");
      setSessionId("");
      setOwner("");
    };
  }, []);

  const handleEndChat = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Chat will be ended Permanently! You can still see them in the chat list.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, end it!",
    }).then(async (result) => {
      if (result.value) {
        await endChatByOwner();
      }
    });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (newMessage.trim() !== "") {
      if (!isOwner) {
        await sendMessageByGuest();
      } else {
        await sendMessageByOwner();
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please enter some message!",
      });
    }
  };

  const handleBack = () => {
    if (auth?.user) {
      navigate("/main/chats", { replace: true });
    } else {
      const uid =
        stickerUid === "" ? sessionStorage.getItem("stickerUid") : stickerUid;
      if (uid) {
        navigate(`/display/${uid}`, {
          replace: true,
        });
      } else {
        navigate(`/`, { replace: true });
      }
    }
  };

  return (
    <div className={styles.container}>
      {isLoadingForMessages && <Loader />}
      {!isLoadingForMessages && (
        <>
          <div className={styles.header}>
            <img
              src={Left}
              alt="Back"
              className={styles.back}
              onClick={handleBack}
            />
            <div className={styles.userInfo}>
              <img className={styles.userPic} src={Avatar} alt="User Pic" />
              <div className={styles.userDetails}>
                <span className={styles.userName}>Anonymous</span>
              </div>
            </div>
            {isActive && (
              <div>
                <button className={styles.endbtn} onClick={handleEndChat}>
                  Block for 24hrs
                </button>
              </div>
            )}
          </div>
          <ScrollableFeed className={styles.chatMessages}>
            {messages?.map((message, index) => (
              <div
                key={index}
                className={`${styles["message"]} ${(message.sender === "Owner" && isOwner) ||
                  (message.sender === "Guest" && !isOwner)
                  ? styles["messageMe"]
                  : styles["messageOther"]
                  }`}
              >
                {message.content}
              </div>
            ))}
          </ScrollableFeed>
          {(!isOwner || isActive) && (
            <div>
              <form onSubmit={handleSendMessage} className={styles.chatInput}>
                <div className={styles.inputField}>
                  <input
                    type="text"
                    autoFocus
                    placeholder="Write your message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  {/* <img src={Emoji} alt="Emoji" className={styles.emoji} /> */}
                </div>
                <button
                  disabled={loadingSendMessage}
                  type="submit"
                  className={styles.sendBtn}
                >
                  <img
                    src={loadingSendMessage ? Spinner : Send}
                    alt="Send"
                    className={styles.send}
                  />
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Chat;
