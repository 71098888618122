import React, { useState } from 'react';
import styles from './ForgetPassword.module.css';

// packages
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

// custom
import { submitForgetPassword } from '../../api/auth/auth';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      handleForgetPassword(values);
    },
  });

  const handleForgetPassword = async (values) => {
    setIsLoading(true);
    const res = await submitForgetPassword(values);
    setIsLoading(false);
    if (res.error) {
      Swal.fire('Something went wrong', res.message, 'error');
      return;
    } else {
      Swal.fire(
        'Yo MasterFam!',
        'Link Sent successfully to your Email address!',
        'success'
      );
      return navigate('/login', { replace: true });
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.topHeader}>
          Enter Email to get Reset Password Link
        </div>
        <center>
          <form onSubmit={formik.handleSubmit}>
            <input
              className={styles.inputBox}
              type="text"
              placeholder="Mail ID"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoFocus
            />
            <br />
            <br />
            <button
              type="submit"
              disabled={isLoading}
              className={`${styles.sBtn} ${isLoading && styles.btnDisabled}`}
            >
              Send Email
            </button>
            <br />
          </form>

          <Link className={styles.redirectLink} to="/login">
            Login
          </Link>
        </center>
      </div>
    </div>
  );
};

export default ForgetPassword;
