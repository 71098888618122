import React from 'react';
import LoginForm from '../components/login/LoginForm';

const LoginPage = () => {
    return (
        <>
            <LoginForm />
        </>
    )
}

export default LoginPage;
