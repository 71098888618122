import { newRefreshToken } from '../api/auth/auth';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();


    const refresh = async () => {
        const response = await newRefreshToken();
        if (response.error) {
            setAuth({});
            return;
        }
        setAuth(prev => {
            return {
                ...prev,
                user: response.data.user,
                role: response.data.user.role,
                accessToken: response.data.token
            }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;
