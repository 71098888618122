import React from "react";
import "./display.css";
import thanks1 from "./assets/thanks1.svg";
import tlogo from "./assets/tlogo.jpg";

function Thanks() {

  const handleLinkClicks = (link) => {
    window.location.replace(link)
  }
  return (
    <div className="thead">
      <center>
        <img src={tlogo} alt="logo" />

        <div className="thead2">
          The item you aim to assist with <br />
          is currently under the owner's control!
        </div>

        <img src={thanks1} alt="thanks" />

        <div className="thead3">Machau Technology</div>

        <div className="thead4">
          Effortlessly find and track important <br />
          belongings with our advanced tracking system!
        </div>

        <button onClick={() => handleLinkClicks("https://mastertag.in")} className="tbut1"> Shop MasterSticker </button>
        <br />
        <button onClick={() => handleLinkClicks("https://mastertag.in")} className="tbut2"> Shop MasterTag </button>

        <div className="thead5">
          Built by IIT Kharagpur Grads | Made in India
        </div>

        <div className="thead6">
          Powered by MasterTag
        </div>

      </center>



    </div>
  );
}

export default Thanks;
