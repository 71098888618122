import React from 'react';

import FeedForm from '../components/feed/FeedForm';

const FeedPage = () => {
    return (
        <>
            <FeedForm />
        </>
    )
}

export default FeedPage;