import io from "socket.io-client";
import { back_url } from "../util/constants";
import { useState, createContext, useEffect, useContext } from "react";
import useChat from "../hooks/useChat";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(io(back_url));
    const { setMessages, setTriggerFetchChats, chats, notifications, setNotifications, fetchAllChats, updateChatNotifications } = useChat();
    const { auth } = useAuth();

    const location = useLocation().pathname;

    // console.log(location, "1");


    const handleReceivedMessage = async (newMessageRecieved) => {

        // console.log(location, "2");

        switch (true) {
            case location.includes(`main/chats/${newMessageRecieved?.sessionId}`): {
                setMessages((prev) => [...prev, newMessageRecieved]);
                // console.log("1");
                return;
            }

            case location.includes(`main/chats`): {
                await fetchAllChats();
                setNotifications((prev) => [...prev, newMessageRecieved]);
                await updateChatNotifications([...notifications, newMessageRecieved]);
                // console.log("2")
                return;
            }

            case location.includes(`main`): {
                setNotifications((prev) => [...prev, newMessageRecieved]);
                await updateChatNotifications([...notifications, newMessageRecieved]);
                // console.log("3")
                return;
            }

            default: {
                setMessages((prev) => [...prev, newMessageRecieved]);
            }

        }


    }

    const handleOnConnection = (data) => {


        // setSocketConnected(true);
        // console.log(data);

    }

    const handleNewChatSessionCreation = (data) => {
        console.log("New session created", data);
    }

    const handleNotification = (data) => {
        console.log("read")
        setNotifications(data);
    }

    useEffect(() => {

        if (auth?.user) {
            socket.emit("setup", auth?.user?.id);
        }

        if (chats?.length) {
            chats?.forEach(element => {
                if (element.isActive) {
                    // console.log(element.sessionId)
                    socket.emit("setup", element.sessionId);
                }
            });
        }

        socket.on("connected", handleOnConnection);

        socket.on("newChatSession", handleNewChatSessionCreation);

        socket.on("receiveMessage", handleReceivedMessage);

        socket.on("updateNotification", handleNotification);

        return () => {
            console.log("Cleaning of...")
            socket.off("receiveMessage", handleReceivedMessage);
            socket.off("connected", handleOnConnection);
            socket.off("newChatSession", handleNewChatSessionCreation);
            socket.off("updateNotification", handleNotification);
        }
    }, [chats, location, socket])

    return (
        <SocketContext.Provider value={{ socket, setSocket }}>
            {children}
        </SocketContext.Provider >
    )
}

export default SocketContext;