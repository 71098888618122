import axios from "./../axios";

const REGISTER_URL = "/v1/api/auth/register";
const LOGIN_URL = "/v1/api/auth/login";
const REFRESH_TOKEN_URL = "/v1/api/auth/refresh-tokens";
const LOGOUT_URL = "/v1/api/auth/logout";
const RESET_PASSWORD = "/v1/api/auth/reset-password?token=";
const FORGET_PASSWORD = "v1/api/auth/forgot-password";

export const submitSignUp = async (data) => {
    try {
        const response = await axios.post(
            REGISTER_URL,
            data,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }

}

export const submitLogin = async (data) => {
    try {
        const response = await axios.post(
            LOGIN_URL,
            data,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

export const submitResetPassword = async (data) => {
    const body = {
        password: data.password
    }
    const token = data.token;
    try {
        const response = await axios.post(
            `${RESET_PASSWORD}${token}`,
            body,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

export const submitForgetPassword = async (data) => {
    try {
        const response = await axios.post(
            FORGET_PASSWORD,
            data,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

export const newRefreshToken = async () => {
    try {
        const response = await axios.get(
            REFRESH_TOKEN_URL,
            {
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }
}


export const logOutMe = async () => {
    try {
        const response = await axios.get(
            LOGOUT_URL,
            {
                withCredentials: true
            },
        );
        return {
            error: false,
            data: response.data
        };
    }
    catch (err) {
        console.log(err);
        return {
            error: true,
            code: err.response ? err.response.data.code : err.code,
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

