import React from 'react';
import Sticker from './../components/landing/Sticker';

const StickerPage = () => {
    return (
        <>
            <Sticker />
        </>
    )
}

export default StickerPage;