import React from 'react'
import RegisterForm from './../components/register/RegisterForm';

const RegisterPage = () => {
    return (
        <>
            <RegisterForm />
        </>
    )
}

export default RegisterPage;