import React from 'react'
import Scanner from './../components/scanner/Scanner';

const ScannerPage = () => {
    return (
        <>
            <Scanner />
        </>
    )
}

export default ScannerPage