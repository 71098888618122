import React from 'react';
import "./Navbar.css";

// packages
import { Link, useLocation, useNavigate } from 'react-router-dom';

// custom
import useAuth from './../../hooks/useAuth';
import useLogout from './../../hooks/useLogout';

// assets
import logo from "./assets/logo.svg";
import useChat from '../../hooks/useChat';

const Navbar = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();
    const logout = useLogout();
    const { notifications } = useChat();

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    }

    const handleLinkClicks = (link) => {
        window.location.replace(link)
    }




    return (
        <div id="myTopnav" className="navbar">


            <Link to="/main" className="hash">
                <img alt="" className="nlogo" src={logo} />
            </Link>
            {(!auth.accessToken || !path.includes("main")) && <>
                {/* <button onClick={() => handleLinkClicks("https://mastertag.in/features")} className="buy-btn3">How it Works</button> */}
                {/* <button onClick={() => handleLinkClicks("https://mastertag.in/help")} className="buy-btn3">Help</button> */}


                {/* <button  className="buy-btn3">Help2</button> */}


                {/* <button onClick={() => navigate("/main", { replace: true })} className="buy-btn3">Sticker</button> */}

                <button onClick={() => handleLinkClicks("https://mastertag.in")} className="buy-btn9">Shop</button>
            </>}
            {auth.accessToken && path.includes("main") && <div className="logout__box">
                <Link className="chatAlert" to="/main/chats">
                    <button className="buy-btn2">Chats</button>
                    {notifications?.length > 0 &&
                        <span className="cnt">{notifications?.length}</span>
                    }
                </Link>
                <button onClick={handleLogout} className="buy-btn">Log out</button>
            </div>}
        </div>
    )
}

export default Navbar;