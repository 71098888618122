import React, { useEffect, useState } from "react";
import styles from "./Found.module.css";
// packages
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";

// images
import i1 from "./assets/i1.svg";
import i3 from "./assets/i3.svg";
import i2 from "./assets/i2.svg";
import shead4 from "./assets/shead4.svg";
import logo from "./assets/logo.svg";

// custom
import Loader from "../common/Loader";
import axios from "../../api/axios";
import useSticker from "../../hooks/useSticker";
import useChat from "../../hooks/useChat";
import Thanks from "../display/Thanks";
import Inactive from "../display/Inactive";

// Extras
import { DISPLAY_URL } from "../../util/constants";
import { truncateString } from "../../util/util";


const Found = () => {
  // states
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activateButton] = useState(isMobile);
  const [isLoadingForChat, setIsLoadingForChat] = useState(false);
  const [message, setMessage] = useState("");

  // custom hooks
  const { setStickerUid } = useSticker();
  const {
    setSessionId,
    setStickerUid: setStickerUidForChat,
    setOwner,
  } = useChat();

  // react-route-dom
  const navigate = useNavigate();
  const params = useParams();

  // functions
  const handlePhoneButton = (type) => {
    const phoneNumber = data.phone;
    if (activateButton) {
      let link;
      if (type === "phone") {
        link = `tel:${phoneNumber}`;
      } else {
        link = `sms:${phoneNumber}`;
      }

      window.location.href = link;
    }
  };

  const handleEmailButton = () => {
    const subject = `Lost Item Found: ${data.stickerName}`;
    const emailLink = `mailto:${data.email}?subject=${encodeURIComponent(
      subject
    )}`;
    window.location.href = emailLink;
  };

  const handleSocialLinkButton = () => {
    const lowercase = data.socialLink.toLowerCase();
    const uri = lowercase.includes("http")
      ? data.socialLink
      : "https://" + data.socialLink;
    const newTab = window.open(uri, "_blank");
    newTab.focus();
  };

  const handleStartChat = () => {
    if (isLoadingForChat) {
      return;
    }
    startChatSession();
  };

  const startChatSession = async () => {
    setIsLoadingForChat(true);

    try {
      const res = await axios.post(
        "/v1/api/chats/create-chat",
        {
          stickerUid: params.stickerId,
        },
        {
          withCredentials: true,
        }
      );
      setSessionId(res.data.sessionId);
      setStickerUidForChat(params.stickerId);
      sessionStorage.setItem("stickerUid", params.stickerId);
      navigate(`/display/chats/${res.data.sessionId}`, { replace: true });
    } catch (err) {
      console.log(err);
      if (err.response) {
        const msg = err.response.data.message;
        Swal.fire({
          title: "Error!",
          text: msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
        });
      }
    } finally {
      setIsLoadingForChat(false);
    }
  };

  const getFeed = async () => {
    try {
      const res = await axios.get(DISPLAY_URL + params.stickerId, {
        withCredentials: true,
      });
      setData(res.data);
      setOwner(res.data.owner);
    } catch (err) {
      console.log(err);
      const code = err.response.data.code;
      const msg = err.response.data.message;
      if (code === 404 && msg === "Sticker not found!") {
        setError(code);
        setMessage(msg);
        Swal.fire({
          title: "Error!",
          text: "Sticker not found!",
          icon: "error",
        });
      } else if (code === 404 && msg === "Sticker not in use by user!") {
        setError(code);
        setMessage(msg);
        Swal.fire({
          title: "Info!",
          text: "Sticker not in use by MasterFam!",
          icon: "info",
        });
      } else if (code === 404 && msg === "Sticker not active!") {
        setStickerUid(params.stickerId);
        setMessage(msg);
        navigate("/main/create", { replace: true });
      } else if (code === 401) {
        setError(code);
        Swal.fire({
          title: "Info!",
          text: "Current product is with our MasterFam!",
          icon: "info",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeed();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className={styles.main}>
        {!loading && error === null && Object.keys(data).length > 0 && (
          <center>
            {/* <img alt="" className="foundLogo" src={logo} /> */}

            <div className={styles.sfd}>
              {data && (
                <img
                  width="60"
                  height="60"
                  marginTop="2%"
                  style={{
                    borderRadius: "50%",
                  }}
                  alt="user"
                  src={data.displayPic || logo}
                />
              )}

              <div className={styles.s8head2}>
                Did you find my lost item? Please return it for a reward. Thank
                you for doing the right thing!
              </div>

              {data.name && (
                <div className={styles.s8head3}>
                  Please Contact:
                  <br />“{data.name}”
                </div>
              )}

              {data.phone && (
                <div className={styles.sfound}>
                  <div className={styles.sfound1}>
                    <img alt="" src={i1} className="fi1" />
                  </div>

                  <div className={styles.sfound2}>
                    <div className={styles.sft1}>{truncateString(data.phone)}</div>
                  </div>

                  <div className={styles.sfound3}>
                    <button
                      disabled={!activateButton}
                      onClick={() => handlePhoneButton("phone")}
                      className={`${styles.fndBtn} ${!activateButton ? styles.btnDisabled : styles.btnActive
                        }`}
                    >
                      Call
                    </button>
                  </div>

                  <div className={styles.sfound4}>
                    <button
                      disabled={!activateButton}
                      onClick={() => handlePhoneButton("sms")}
                      className={`${styles.fndBtn} ${!activateButton ? styles.btnDisabled : styles.btnActive
                        }`}
                    >
                      SMS
                    </button>
                  </div>
                </div>
              )}

              {data.email && (
                <div className={styles.sfndb}>
                  <div className={styles.sfndb1}>
                    <img alt="" src={i3} className="fi1" />
                  </div>

                  <div className={styles.sfndb2}>
                    <div className={styles.sft1}>{truncateString(data.email)}</div>
                  </div>

                  <div className={styles.sfndb3}>
                    <button onClick={handleEmailButton} className={styles.fndBtn}>
                      Mail
                    </button>
                  </div>
                </div>
              )}

              {data.socialLink && (
                <div className={styles.sfndb}>
                  <div className={styles.sfndb1}>
                    <img alt="" src={i2} className="fi1" />
                  </div>

                  <div className={styles.sfndb2}>
                    <div className={styles.sft1}>{truncateString(data.socialLink)}</div>
                  </div>

                  <div className={styles.sfndb3}>
                    <button onClick={handleSocialLinkButton} className={styles.fndBtn}>
                      Open
                    </button>
                  </div>
                </div>
              )}

              <img alt="" src={shead4} className={styles.shead4} />

              <button
                onClick={handleStartChat}
                type="button"
                disabled={isLoadingForChat}
                className={`${styles.fndBtn2} ${isLoadingForChat ? styles.btnDisabled : styles.btnActive
                  }`}
              >
                Message me Privately
              </button>
              <div className={styles.fndPr}>Messaging is 100% private & anonymous</div>

              <div className={styles.fndWhat}>What is this?</div>

              <div className={styles.fndPow}>Powered by MasterTag</div>
            </div>
            <br />
            <br />
            <br />
          </center>
        )}
        {!loading && error === 404 && message === "Sticker not found!" && (
          <center>
            <p>Sticker Not Accessable</p>
          </center>
        )}
        {
          !loading &&
          error === 404 &&
          message === "Sticker not in use by user!" && <Inactive />
          // <center>
          //     <p>Sticker Not Accessable</p>
          // </center>
        }
        {
          !loading && error === 401 && <Thanks />
          // <center>
          //     <h2>The Product is with Me :)</h2>
          //     <p>Thank You for Scanning Master Sticker !!!</p>
          // </center>
        }
      </div>
    </>
  );
};

export default Found;
