import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';
import Loader from "../common/Loader";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    useEffect(() => {
        const verifyRefreshToken = async () => {
            await refresh();
            setIsLoading(false);
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    }, [])


    return (
        <>
            {
                isLoading
                    ? <Loader />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin