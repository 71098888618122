export const back_url = process.env.REACT_APP_BACK_URL;
export const display_key = process.env.REACT_APP_DISPLAY_KEY;
export const STICKERS_URL = "/v1/api/stickers/user/";
export const DISPLAY_URL = "/v1/api/stickers/";
export const CHAT_URL = "/v1/api/chats/";
export const END_CHAT_BY_OWNER_URL = "/v1/api/chats/end-chat";
export const OWNER_MESSAGES_URL = "/v1/api/messages/user/get-messages";
export const OWNER_SEND_MESSAGE_URL = "/v1/api/messages/user/send-message";
export const GUEST_MESSAGES_URL = "/v1/api/messages/get-messages";
export const GUEST_SEND_MESSAGE_URL = "/v1/api/messages/send-message";
