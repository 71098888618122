import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styles from "./ResetPassword.module.css";
import * as yup from "yup";
import { submitResetPassword } from "../../api/auth/auth";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Your password is too short.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Password should contain both letters and numbers"
    ),
  cPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token] = useState(queryParams.get("token"));

  const formik = useFormik({
    initialValues: {
      password: "",
      cPassword: "",
    },
    onSubmit: (values) => {
      const { password, cPassword } = values;
      if (password === "" || cPassword === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill all the fields!",
        });
        return;
      }
      handleResetPassword(values);
    },
    validationSchema,
  });

  const handleResetPassword = async (values) => {
    const data = {
      password: values.password,
      token,
    };
    setIsLoading(true);
    const res = await submitResetPassword(data);
    setIsLoading(false);
    if (res.error) {
      Swal.fire("Something went wrong", res.message, "error");
      return;
    } else {
      Swal.fire("Yo MasterFam!", res.data.message, "success");
      return navigate("/login", { replace: true });
    }
  };
  const passwordError = formik.touched.password && formik.errors.password;
  const cPasswordError = formik.touched.cPassword && formik.errors.cPassword;
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.topHeader}>Enter New Password</div>
        <center>
          <form onSubmit={formik.handleSubmit}>
            <input
              className={`${styles.inputBox} ${
                passwordError && styles.errorInput
              }`}
              type={!showPassword ? "password" : "text"}
              placeholder="Enter new password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              autoFocus
              {...formik.getFieldProps("password")}
            />

            <div className={styles.rhide}>
              <button
                className={styles.passwordToggleButton}
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            {passwordError && (
              <div className={styles.errorText}>{formik.errors.password}</div>
            )}
            <br />
            <div className={styles.passwordInputContainer}>
              <input
                className={`${styles.inputBox} ${
                  cPasswordError && styles.errorInput
                }`}
                type={!showCPassword ? "password" : "text"}
                placeholder="Confirm new password"
                name="cPassword"
                value={formik.values.cPassword}
                onChange={formik.handleChange}
                {...formik.getFieldProps("cPassword")}
              />

              <div className={styles.rhide}>
                <button
                  className={styles.passwordToggleButton}
                  type="button"
                  onClick={() => setShowCPassword((prev) => !prev)}
                >
                  {showCPassword ? (
                    <FaEye className={styles.passwordIcon} />
                  ) : (
                    <FaEyeSlash className={styles.passwordIcon} />
                  )}
                </button>
              </div>

              {cPasswordError && (
                <div className={`${styles.errorText}`}>
                  {formik.errors.cPassword}
                </div>
              )}
            </div>

            <br />
            <br />
            <button
              type="submit"
              disabled={isLoading}
              className={`${styles.sBtn} ${isLoading && styles.btnDisabled}`}
            >
              Change Password
            </button>
            <br />
          </form>
        </center>
      </div>
    </div>
  );
};

export default ResetPassword;
