import ResetPassword from './../components/resetPassword/ResetPassword';

import React from 'react';

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPassword />
    </>
  );
};

export default ResetPasswordPage;
