import React, { useState } from 'react';
import styles from './Login.module.css';
// packages
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// custom
import { submitLogin } from '../../api/auth/auth';
import useAuth from '../../hooks/useAuth';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Your password is too short.')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      'Password should contain both letters and numbers'
    ),
});

const LoginForm = () => {
  const { setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/main';

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleLogin = async (values) => {
    setIsLoading(true);
    const res = await submitLogin(values);
    setIsLoading(false);
    if (res.error) {
      if (res.code === 'ERR_NETWORK') {
        Swal.fire('Something went wrong', res.message, 'error');
      } else {
        Swal.fire('Invalid Credentials', res.message, 'error');
      }
      return;
    }
    setAuth({
      user: res.data.user,
      accessToken: res.data.token,
      role: res.data.user.role,
    });
    formik.resetForm();

    navigate(from, { replace: true });
  };
  const passwordError = formik.touched.password && formik.errors.password;
  const emailError = formik.touched.email && formik.errors.email;
  return (
    <div className={styles.main}>
      <div>
        <div className={styles.topHeader}>Login to sticker account</div>
        <center>
          <form>
            <input
              className={`${styles.inputBox} ${
                emailError && styles.errorInput
              }`}
              type="email"
              placeholder="Mail ID"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              {...formik.getFieldProps('email')}
            />
            {emailError && (
              <div className={styles.errorText}>{formik.errors.email}</div>
            )}
            <br />
            {/* <div
              className={`${styles.passwordBox} ${
                (passwordError || emailError) && styles.passwordBoxError
              }`}
            > */}

            <input
              className={`${styles.inputBox} ${styles.passwordInput} ${
                passwordError && styles.errorInput
              }`}
              type={!showPassword ? 'password' : 'text'}
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              {...formik.getFieldProps('password')}
            />

            <div className={styles.hideBtn}>
              <button
                className={styles.passwordToggleButton}
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            {passwordError && (
              <div className={styles.errorText2}>{formik.errors.password}</div>
            )}
            {/* </div> */}

            <br />

            <Link to="/forget-password">
              <div className={styles.forgetPassword}>Forget Password?</div>
              {/* <img alt="" src="./images/s4head1.svg" className="s4head1" /> */}
            </Link>
          </form>
          <button
            onClick={formik.handleSubmit}
            disabled={isLoading}
            type="submit"
            className={`${styles.sBtn} ${isLoading && styles.btnDisabled}`}
          >
            Login
          </button>

          <br />

          <br />
          <Link
            style={{
              display: 'block',
              color: '#FF1515',
              textDecoration: 'underline',
            }}
            to="/register"
          >
            Signup
          </Link>
        </center>
      </div>
    </div>
  );
};

export default LoginForm;
