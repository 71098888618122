import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnLocationChange = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    return null; // This component doesn't render anything
};

export default ScrollToTopOnLocationChange;
