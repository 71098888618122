import React, { useEffect, useContext } from "react";
import styles from "./alerts.module.css";

// packages
import { useNavigate } from "react-router-dom";

// assets
// import Read from './assets/Read.png';
import Unread from "./assets/Unread.png";
import Read from "./assets/Read.png";
import Loader from "../common/Loader";
import useChat from "../../hooks/useChat";
import Swal from "sweetalert2";
import SocketContext from "../../context/SocketContext";
import useAuth from "../../hooks/useAuth";

const Alerts = () => {

  const { socket } = useContext(SocketContext);

  // state


  // hooks
  const navigate = useNavigate();

  // custom hooks

  const {
    setSessionId,
    setOwner,
    chats,
    setChats,
    isLoading,
    setIsLoading,
    fetchAllChats,
    notifications
  } = useChat();

  const { auth } = useAuth();

  const getAllChats = async () => {
    const res = await fetchAllChats();
    console.log(res);
    if (res.error) {
      Swal.fire({
        icon: "error",
        title: "Oops Something Went Wrong!",
        text: res.message,
      });
      if (res.code === 401 || res.code === 403) {
        navigate("/login", { replace: true });
      }
    }
  }


  // useEffect(() => {
  //   console.log(auth);
  //   if (auth?.user)
  //     getAllChats();
  // }, [auth]);

  const handleChatClick = (sessionId, user) => {
    setSessionId(sessionId);
    setOwner(user);
    sessionStorage.setItem("sessionId", sessionId);
    sessionStorage.setItem("owner", user);
    socket.emit("readMessage", { sessionId, notifications, user });
    navigate(`${sessionId}`, { replace: true });
  };

  // console.log(chats);

  return (
    <div className={styles.container}>
      <h2 className={styles.company}>MasterSticker</h2>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.list}>
          {chats?.map((chat) => (
            <div
              key={chat.sessionId}
              className={`${styles.notification} ${styles.unread}`}
              onClick={() => handleChatClick(chat.sessionId, chat.owner)}
            >
              {chat.lastMessage?.sender === "Guest" && chat.lastMessage?.read ?
                <img src={Read} alt="Mail" className={styles.mailIcon} /> :
                <img src={Unread} alt="Mail" className={styles.mailIcon} />
              }
              <div className={styles.content}>
                <h3 className={styles.title}>
                  {chat?.stickerId?.stickerName || "Product Name"}
                </h3>
                <p className={styles.desc}>
                  {/* {isMobile
                    ? truncateString(chat?.lastMessage?.content, 25)
                    : truncateString(chat?.lastMessage?.content, 200)} */}
                  {chat?.lastMessage?.content}
                </p>
                <span className={styles.time}>
                  {new Date(chat?.lastMessage?.createdAt).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Alerts;

// {
//   notifications.map((notification) => (
//     <div
//       key={notification.id}
//       className={`${styles.notification} ${
//         notification.readStatus ? styles.read : styles.unread
//       }`}
//       onClick={() => handleNotificationClick(notification.id)}
//     >
//       <img
//         src={notification.readStatus ? Read : Unread}
//         alt="Mail"
//         className={styles.mailIcon}
//       />
//       <div className={styles.content}>
//         <h3 className={styles.title}>{notification.title}</h3>
//         <p className={styles.desc}>{notification.description}</p>
//         <span className={styles.time}>{notification.timestamp}</span>
//       </div>
//     </div>
//   ));
// }
