import React from 'react';
import styles from "./Sticker.module.css";

// packges
import { Link } from 'react-router-dom';

// assets
import shead3 from "./assets/shead3.svg";
import scan2 from "./assets/scan2.svg";
import shead4 from "./assets/shead4.svg";

const Sticker = () => {
    return (
        // <center>

        <div className={styles.main}>

            <div className={styles.shead1}>
                MasterSticker
            </div>
            <div className={styles.shead2}>
                Lost & Found
            </div>

            <center>
                <hr className={styles.shr} />

                <img alt='' src={shead3} className={styles.shead3} />
                <br />
                <Link to="/main/scan">
                    <img alt='' src={scan2} className={styles.scan} />
                </Link>
                <br />
                <img alt='' src={shead4} className={styles.shead4} />
                <br />

                <Link to="/login">
                    <button className={styles.slogin2}>
                        Login
                    </button>
                    {/* <img alt='' src="./images/slogin.svg" className="slogin" /> */}
                </Link>
                <br />


            </center>



        </div>

        // </center>
    )
}

export default Sticker;